@mixin clearfix() {
    &::after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: ' ';
        clear: both;
        height: 0;
    }
}

@function em($pixels) {
    @return #{$pixels/$font-size-base}em;
}

@function native-rgba($string, $opacity: 1) {
    @return #{'rgba(#{$string}, #{$opacity})'};
}

@mixin font-base() {
    font-family: $font-lato;
    font-size: #{$font-size-base}px;
}

@mixin bp($size) {
    @media screen and (min-width: $size) {
        @content;
    }
}

@mixin placeholder {
    ::-webkit-input-placeholder {
        @content;
    }

    :-moz-placeholder {
        @content;
    }

    ::-moz-placeholder {
        @content;
    }

    :-ms-input-placeholder {
        @content;
    }
}
