$env: production;
@import '../../node_modules//flatpickr/dist/themes/light.css';
@import '../../node_modules/prismjs/themes/prism.css';
@import '../scss/core/variables';
@import '../scss/core/mixins';

body.fqd-body.active {
    --color-corp-id-1__l20: #{lighten(red, 20%)};
    --color-corp-id-1: red;
    --color-corp-id-2: magenta;
    --color-corp-id-2__d15: #{darken(magenta, 15%)};

    --color-corp-id-3: green;
    --color-corp-id-3__l2: #{lighten(green, 2%)};
    --color-corp-id-3__l10: #{lighten(green, 10%)};

    --color-foreground: #48485c;
    --color-canvas: #ebf1f5;
    --color-canvas__d5: #dae5ed;
    --color-canvas__d15: #b8cddc;

    --color-notification: yellow;
    --color-notification__l25: #{lighten(yellow, 25%)};
    --color-status: #fdf7a1;
    --color-confirm: #7fb666;
    --color-confirm__d15: #5a8c43;
}

@mixin clearfix() {
    &::after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: ' ';
        clear: both;
        height: 0;
    }
}

.fqd-body {
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    background: rgba(var(--color-white), 1);

    & > main {
        display: flex;
        align-items: stretch;
        flex: 1;
    }
}

main {
    width: 100%;
    display: flex;
}

.page-footer {
    float: left;
    font-size: 12px;
    padding: 48px 16px;
}

.toolkit-header {
    padding: 40px 16px;
    font-size: 36px;
    background: rgba(var(--color-secondary__neutral), 1);
    display: flex;

    h1 {
        font-size: 30px;
        line-height: 1.2;
        display: flex;
        flex-direction: column;
    }

    small {
        display: block;
        font-size: 13px;
        @include clearfix();
    }

    img {
        height: 50px;
        margin-right: 25px;
    }

    a {
        text-decoration: none;
        display: flex;
        align-items: center;
        line-height: 1;

        span,
        small {
            color: #ffffff;
        }
    }
}

.sidebar {
    float: left;
    width: 240px;
    background: rgba(var(--color-canvas__lighten5), 1);
    padding: 16px;

    a {
        text-decoration: none;
        text-transform: capitalize;
    }
}

code {
    background: aliceblue;
    border: 1px solid #b5b5b5;
    border-radius: 3px;
    padding: 3px;
}

.canvas {
    float: left;
    width: calc(100% - 250px);
    padding: 16px;

    .component-title {
        text-transform: capitalize;
        font-size: 30px;
        font-weight: bold;
        color: #656565;
        padding-bottom: 16px;
        border-bottom: 1px solid #c5c5c5;
        margin-bottom: 48px;
    }

    .component-title-variation {
        font-size: 24px;
        margin-bottom: 16px;
        font-weight: bold;
        color: #656565;
    }

    .icon-grid {
        margin-top: 3em;
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
        grid-template-rows: minmax(100px, 1fr);
        grid-gap: 1em;
    }

    .icon-holder {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 7em;

        &__the-icon {
            display: flex;
            width: 3em;
            min-height: 3em;
            margin-bottom: 0.3em;
            font-size: 3em;
            background-color: #f1f1f1;
            justify-content: center;
            align-items: center;
        }

        &__description {
            text-align: center;
            font-size: 1em;
        }
    }
}

.component-stage {
    margin-bottom: 32px;
    padding-bottom: 48px;
    border-bottom: 1px solid #c5c5c5;
    @include font-base();
}

.header-sample {
    position: relative;
    overflow-x: hidden;
    overflow-y: visible;
    min-height: 800px;
}

.prism-pre {
    background: lighten(#48485d, 65%);
    border: 1px solid #aaaaaa;
    border-radius: 6px;
    padding: 16px;
    margin: 16px 0;
    width: 100%;
    max-height: 30em;
}

body {
    font-family: sans-serif;
    .template-container {
        min-height: 500px;
        @include clearfix();
        max-width: 1280px;

        a {
            text-decoration: none;
        }

        h1 {
            font-size: 2em;
            margin-bottom: 1em;
        }

        h2 {
            font-size: 1.5em;
            margin-bottom: 1em;
        }

        h3 {
            font-size: 1.2em;
            margin-bottom: 1em;
        }

        h4 {
            font-size: 1em;
            margin-bottom: 1em;
        }

        p {
            line-height: 1.5;
        }
    }
}

*,
*:after,
*:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
