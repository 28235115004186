//system layout core colors
$fontPath: null;

@if ($env == 'development') {
    $fontPath: '/src/fonts';
} @else {
    $fontPath: '/fonts';
}

$color-white: #ffffff;
$color-black: #000000;
$color-gray-light: #dde4e8;
$color-gray-mid: #d4d9dd;
$color-gray: #b0b5b8;
$color-yellow: #e2b82d;
$color-light-blue: #f5f8fa;

//Font configs
$font-size-base: 14 !default;
$font-size-form-notification: 0.8em;

$font-remo: var(--font-primary);
$font-remo-bold: var(--font-primary-bold);
$font-lato: var(--font-secondary);
$font-lato-bold: var(--font-secondary-bold);
$font-icons: 'fq-icons';

//default values
$border-radius: 0.187em;
$box-shadow: 0 1px 2px 1px rgba($color-black, 0.1);

//breakpoints (from bootstrap)

$screen-sm-min: 768px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-md-min: 992px;
$screen-sm-max: ($screen-md-min - 1);
$screen-lg-min: 1200px;
$screen-md-max: ($screen-lg-min - 1);

////////////////////////////////////////////////////////
//        Default configuration for components        //
////////////////////////////////////////////////////////

//button
$button-font: normal 1em var(--font-primary-bold);
$button--medium-padding: 0.65em 1em;
$button--medium-padding: 0.85em 1em;
$button__icon-padding: 0 0.55em 0 0;
$button-padding: 1em;
$button-radius: $border-radius;

//input
$input-border-color: #d4d9dd;
$input-border-color--hover: #bfc3c7;
$input-placeholder-color: #b0b5b8;
$input-text-color: #9aa3aa;
$input-background-color: $color-white;
$input-padding: 0.77em;
$input-radius: $border-radius;
$input-font-style: normal 1em $font-lato;

//formfield
$formfield-padding: 0 0 1em;
$formfield__label-color: #48485c;
$formfield__label-padding: 0.8em 0;
$formfield__label-font: normal 1em $font-lato;
$formfield__message-font-size: $font-size-form-notification;
$formfield__message-font: $font-lato;
$formfield__iconinput-padding: 0 0 0 2em;
$formfield__message-padding: 0.5em 0 0 0.5em;

//input boxes(radio, select)
$selectbox-color: #48485c;
$selectbox__item-border-color: #cfd3d6;
$selectbox__item-border: 1px solid $selectbox__item-border-color;
$selectbox-border-radius: $border-radius;
$selectbox-font: normal 1em $font-lato;
$selectbox__text-padding: 0 0 0 2em;
$selectbox__text-font: $input-font-style;
$selectbox__message-padding: 0 0 0 2.5em;

//link
$link-color: var(--color-corp-id-2);

//dropdown 
$dropdown-bgcolor: $color-white;
$dropdown-border-color: #c9cdd1;
$dropdown-border: 0.06em solid $dropdown-border-color;

//card
$card-bgcolor: $color-white;
$card-border-color: #c9cdd1;
$card-border: 0.06em solid $card-border-color;
$card-border-radius: $border-radius;
$card-boxshadow: $box-shadow;
$card--dark-bgcolor: $color-light-blue; //it was suposed to be $color-canvas, but upload background is equals. so, it has changed.
$card__header-padding: 2em 2.5em;

//notification
$notification-margin: 1.2em 0;
$notification__header-padding: 0.86em 1em;
$notification__header-icon-margin: 0 0.5em 0 0;
$notification__content-background: $color-white;
$notification__content-color: var(--color-corp-id-1);
$notification__content-padding: 1em 1.45em;
$notification__list-item-border-color: $color-gray;
